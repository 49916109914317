import { Stack } from "@mui/material";
import axios from "axios";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageWrapper from "../components/layout/PageWrapper";
import Loading from "../components/Loading/loading";
import Page from "../components/Page";
import { APPLICATION_DETAILS } from "../config/apiSlugs";
import { defaultHomeJson } from "../config/defaultValues";
import ERRORS from "../config/errorSlugs";
import { reduxHelper } from "../config/reduxHelper";
import { dynamicComponents } from "../siteThemes/components";

function Homepage({ pageData, appData ,pageError,jsonPath})
{
    const [pageComponents, setPageComponents] = useState(null);

    const { reducer } = useSelector((state) => state);
    const {showAlert, user} = reducer;
    const [isLoading, setLoading] = useState(true)
    
    const dispatch = useDispatch()
    const { replace } = useRouter()

    console.log(pageData,"Testi88")
    console.log(pageError,"Test22")
    console.log(jsonPath,"Tesy44")
    useEffect(() => {
        const result = pageData?.components?.map((item, index) => {
            return dynamicComponents[item.key]
        });
        setPageComponents(result);
    }, [pageData]);

    useEffect(() => {
        dispatch({
            type: reduxHelper.UPDATE_THEME_BUILDER_JSON,
            payload: pageData
        });
        dispatch({
            type: reduxHelper.UPDATE_PAGE_JSON,
            payload: pageData
        });
    }, [pageData]);

    useEffect(() => {
        
        if(showAlert)
        {
            dispatch({
                type: reduxHelper.UPDATE_ALERT,
                payload: false
            });
        }

    })

    useEffect(() => {
        setLoading(true)
        if(appData?.app_id == '530' && user)
        {
            replace('/p/landing')
            setLoading(false)
        }
        else
        {
            setLoading(false)
        }
    }, [user])

    useEffect(() => {
        if(typeof window['translateNode'] === "function") 
        {
            window['translateNode'](document.body, 'en');
        }
    }, [])
        

    if(isLoading)
    {
        return <Loading/>
    }

    return (
        <Page title={pageData?.seoProperties?.metaTitle || appData?.application_title} >

            <PageWrapper pageData={pageData} >

                <Stack
                    style={{
                        ...pageData?.pageStyle,
                        gap: undefined,
                        paddingLeft: pageData?.pageStyle?.paddingLeft,
                        paddingRight: pageData?.pageStyle?.paddingRight,
                    }}
                    width={'100%'}
                    gap={pageData?.pageStyle?.gap}
                    minHeight={'100vh'}
                >
                    {
                        pageComponents?.map((Component, index) => {
                            return (
                                <Component key={index} />
                            )
                        })
                    }

                </Stack>

            </PageWrapper>
    
        </Page>
    )
};

export default Homepage; 

export async function getServerSideProps({ req, res, ...ctx })
{
    
    let props = {}

    try 
    {

        let host        = '';
        const reqHost   = req.headers.host;
       

        if((reqHost ) && reqHost !== 'localhost:3000') 
        {
            host = reqHost;
            
        }

        if(host === '') 
        {
            throw new Error(ERRORS.HOST_EMPTY.code);
            console.log(host,"host1")
        }

        let protocal = 'http://'
        
        if(req.headers['x-forwarded-proto'] ) 
        {
            protocal = 'https://'
           
        }

        const applicationUrl        = protocal + host + process.env.API_SLUG + APPLICATION_DETAILS;
        const applicationResponse   = await fetch(applicationUrl);
        const applicationJson       = await applicationResponse.json();

        
        if(applicationResponse.status !== 200)
        {
            return {
                notFound: true,
            }
        }

        if(typeof applicationJson !== 'object')
        {
            return {
                notFound: true,
            }
        }

        // props['appData'] = applicationJson.data;

        const jsonPath          = applicationJson?.data?.cloudfrontUrl + 'uploads/' + applicationJson?.data?.app_id + '/data/site_builder/index/' + 'aW5kZXg=.json?v=' + Math.random();
       
        const pageJsonResponse = await axios.get(jsonPath)
        const pageResponseJson = await pageJsonResponse.data;
    
        if(pageJsonResponse.status !== 200)
        {
            throw new Error(ERRORS.SITE_BUILDER_FILE_ERROR.code);
        }

        if(typeof pageResponseJson !== 'object')
        {
            throw new Error(ERRORS.SITE_BUILDER_FILE_ERROR.code);
        }

        if(!Array.isArray(pageResponseJson.components))
        {
            throw new Error(ERRORS.SITE_BUILDER_FILE_RESPONSE_ERROR.code);
        }

        if(Array.isArray(pageResponseJson.components) && pageJsonResponse?.components?.length < 1)
        {
            throw new Error(ERRORS.SITE_BUILDER_FILE_RESPONSE_ERROR.code);
        }

        props['pageData'] = pageResponseJson;

 
    } catch (error) {

        props['pageData'] = defaultHomeJson;

        if(error.message === ERRORS.LAYOUT_JSON_ERROR)
        {
            props['pageData'] = pageResponseJson;
        }

        if(error.message === ERRORS.HOST_EMPTY.code) 
        {
            props['error']              = {};
            props['error']['error']     = true;
            props['error']['message']   = STRINGS.SOMETHING_WENT_WRONG;
            props['error']['code']      = ERRORS.HOST_EMPTY.code;
        }

        if(error.message === ERRORS.APPLICATION_DATA_ERROR.code)
        {
            props['error']              = {};
            props['error']['error']     = true;
            props['error']['is404']     = true;
            props['error']['code']      = ERRORS.APPLICATION_DATA_ERROR.code;
        }

        if(error.message === ERRORS.SITE_BUILDER_FILE_ERROR.code)
        {
            props['pageData'] = defaultHomeJson;
        }

        if(error.message === ERRORS.SITE_BUILDER_FILE_RESPONSE_ERROR.code)
        {
            props['pageData'] = defaultHomeJson;
        }

        if(error.message === ERRORS.JSON_ERROR.name)
        {
            props['error']              = {}
            props['error']['error']     = true;
            props['error']['is404']     = true;
        }

        if(error.message === ERRORS.FILE_NOT_FOUND.name)
        {
            props['pageData'] = defaultHomeJson;
        }
        
    }

    return {
        props: props
    }
}