const widgetTypes = {

    WHATSAPP: 'WHATSAPP',

    SCROLL_TOP: 'SCROLL_TOP',

    POPUP: 'POPUP'

}

export {
    widgetTypes
}