import { alpha, Box } from "@mui/material"
import { m, useViewportScroll, useTransform } from "framer-motion";
import Image from "next/image";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import EkhoolImage from "../../ekhoolImage/ekhoolImage";
import AppLogo from "../../logo/Logo";

const BubbleAnimation = () => {

    const { scrollYProgress } = useViewportScroll();
  
    let windowHeight = 100
    let windowWidth  = 100

    if(typeof window === 'object')
    {
        windowHeight = window?.innerHeight;
        windowWidth  = window?.innerWidth;
    }
    
    const scale = useTransform(scrollYProgress, [0, 0.4,1], [1, 2.6, 2.6]);
    const top = useTransform(scrollYProgress, [0, 1], [75, windowHeight - 280], { clamp: false });

    const left = useTransform(scrollYProgress, [0, 0.1, 0.4, 1], [windowWidth - 500,windowWidth - 500, 100, 100], { clamp: false });
    const logoTop = useTransform(scrollYProgress, [0, 0.3, 0.4, 1], [200, 200, -75, -75], { clamp: false });
    const logoScale = useTransform(scrollYProgress, [0, 0.3, 0.4, 1], [2, 1, 0.5, 0.5])

    const miniCircleScale = useTransform(scrollYProgress, [0, 0.4, 1], [1, -1, 0]);
    const miniCircleOpacity = useTransform(scrollYProgress, [0, 0.3, 0.4, 1], [1, 0, 0, 0]);

    const { reducer } = useSelector((state) => state);
    const { pageJson } = reducer;

    const animationProperties = pageJson?.animationProperties;
    const src = animationProperties?.src;
    const color = animationProperties?.color;
    
    return (
        <Box>

            <Box
                position={'fixed'}
                height={250}
                width={250}
                component={m.div}
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
                style={{
                    left: left,
                    top: logoTop,
                    scale: logoScale
                }}
                zIndex={99999}
            >

                <AppLogo
                    height={'5vw'}
                    width={'12vw'}
                />

            </Box>


            <Box
                position={'fixed'}
                height={250}
                width={250}
                component={m.div}
                left={'8vw'}
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
                style={{
                    top
                }}
                zIndex={10}
            >

                {
                    src &&
                    <EkhoolImage src={src} layout={'fill'} objectFit={'contain'} />
                }

            </Box>

            <Box
                position={'fixed'}
                height={'60vw'}
                width={'60vw'}
                component={m.div}
                right={-300}
                style={{
                    scale
                }}
                top={-300}
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
            >

                <Box
                    height={'100%'}
                    width={'100%'}
                    bgcolor={alpha(color, 0.5)}
                    borderRadius={500}
                    position={'absolute'}
                />

                <Box
                    height={'90%'}
                    width={'90%'}
                    bgcolor={color}
                    borderRadius={500}
                    position={'absolute'}
                />

            </Box>

            <Box
                position={'fixed'}
                zIndex={9}
                height={350}
                width={350}
                component={m.div}
                left={-175}
                style={{
                    scale: miniCircleScale,
                    opacity: miniCircleOpacity
                }}
                bottom={-100}
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
            >

                <Box
                    height={'100%'}
                    width={'100%'}
                    bgcolor={alpha(color, 0.5)}
                    borderRadius={500}
                    position={'absolute'}
                />

                <Box
                    height={'80%'}
                    width={'80%'}
                    bgcolor={color}
                    borderRadius={500}
                    position={'absolute'}
                />

            </Box>
        </Box>
    )
};

export default BubbleAnimation;