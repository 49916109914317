import { Stack } from "@mui/material"
import { useSelector } from "react-redux";
import BubbleAnimation from "./BubbleAnimation";
import { animationTypes } from "./types";

const Wrapper = ({ children }) => {
    return (
        <Stack
            height={'100%'}
            width={'100%'}
            position={'fixed'}
        >

            {children}

        </Stack>
    )
}

const BackgroundAnimator = () => {

    const { reducer } = useSelector((state) => state);
    const { pageJson } = reducer;

    const animationProperties = pageJson?.animationProperties;
    const pageAnimation = animationProperties?.pageAnimation;

    if(pageAnimation)
    {
        switch (pageAnimation) {
            case animationTypes.SCROLL_BUBBLES:
                
                return (
                    <Wrapper>  
                        <BubbleAnimation/>
                    </Wrapper>
                )
        
            default:
                return <Wrapper/>
        }
    }

    return (
        <Wrapper/>
    )
};

export default BackgroundAnimator;