import { Box, Fab } from "@mui/material";
import { icons } from "../../config/icons";
import Iconify from "../Iconify";
import ScrollTop from "../ScrollTop";
import BackgroundAnimator from "../siteBuilder/animations/BackgroundAnimator";
import { widgetTypes } from "../widgets/types";

const PageWrapper = ({ pageData, children, ...props }) => {

    const { widgets } = pageData || {};

    const whatsAppWidgetData    = widgets?.filter((i) => (i.type == widgetTypes.WHATSAPP)) || [];
    const whatsAppWidget        = whatsAppWidgetData?.length > 0 ? whatsAppWidgetData[0] : null;
    const whatsAppWidgetEnabled = typeof whatsAppWidget === 'object' && whatsAppWidget?.config !== undefined;
    const whatsAppWidgetConfig  = whatsAppWidget?.config || {};
    const whatsAppWidgetStyle   = whatsAppWidget?.style || {};

    const scrollTopWidgetData    = widgets?.filter((i) => (i.type == widgetTypes.SCROLL_TOP)) || [];
    const scrollTopWidget        = scrollTopWidgetData?.length > 0 ? scrollTopWidgetData[0] : null;
    const scrollTopWidgetEnabled = scrollTopWidget !== null;
    const scrollTopWidgetStyle   = scrollTopWidget?.style || {};

    return (
        <>
            
            {children}

            <BackgroundAnimator/>

            {
                whatsAppWidgetEnabled &&
                <Box
                    position={'fixed'}
                    bottom={65}
                    right={whatsAppWidgetStyle?.position ? whatsAppWidgetStyle?.position === 'right' ? 15 : undefined : 15}
                    left={whatsAppWidgetStyle?.position === 'left' ? 15 : undefined}
                    zIndex={1000}
                >
                    <Fab
                        onClick={() => {
                            window.open(`https://wa.me/${whatsAppWidgetConfig?.number}/?text=${whatsAppWidgetConfig?.message}`)
                        }}
                        size={'small'}
                    >
                        <Iconify icon={icons.WHATSAPP} />
                    </Fab>
                </Box>
            }

            {
                scrollTopWidgetEnabled &&
                <ScrollTop {...props}>
                    <Fab
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            })
                        }}
                        size={'small'}
                    >
                        <Iconify icon={icons.ARROW_UP} />
                    </Fab>
                </ScrollTop>
            }

        </>
    )
};

export default PageWrapper;